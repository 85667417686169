<template>
	<div>
		<!-- 登录-头 -->
		<!-- <el-header> -->
		<login-header :loginTitle="loginTitle"></login-header>
		<!-- </el-header> -->
		<!-- 登录-内容区域 -->
		<!-- <el-container> -->
		<router-view />
		<!-- </el-container> -->
		<!-- 登录-尾 -->
		<!-- <el-footer> -->
		<login-footer></login-footer>
		<!-- </el-footer> -->
	</div>
</template>

<style lang="less" scoped></style>

<script>
import loginHeader from './loginHeader.vue';
import loginFooter from './loginFooter.vue'

export default {
	name: 'loginFrame',
	components: {
		loginHeader,
		loginFooter
	},
	//meta信息seo用
	metaInfo() {
	  return {
	    title: this.$t("meta.main")
	  };
	},
	data() {
		return {
			loginTitle: ''
		};
	},
	
	mounted() {
		// console.log("mounted");
		// this.init();
	},
	methods: {
		init() {
			// console.log(this.$store);
			//  	let userinfo = this.$store.state.login.userInfo;
			//  	// console.log(userinfo);
			//  	if(userinfo){
			//  		this.ruleForm.userCode = userinfo.userCode;
			//  		// this.ruleForm.userType = userinfo.userType;
			// this.ruleForm.password = userinfo.password;
			//  	}
		}
	}
};
</script>
